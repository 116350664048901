import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Box, Grid, Stack, Typography, useTheme, Link } from '@mui/material';

// project import
import MainCard from '../../../../components/MainCard';
import { ReduxSimpleTable, SimpleTableColumn } from '../../../../components/tables/SimpleTable';
import { useSelector } from '../../../../store/hooks';
import { fetchOrganizations } from '../../../../store/slices/bowser/organizations/fetchAll';
import Dot from '../../../../components/@extended/Dot';
import DashboardSearchTool from '../../../../components/form/DashboardSearchTool';
import { CoreUITableProps } from '../../../../lib/core/types';

const columns = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    sortable: true,
    getValue: obj => (
      <Typography>
        <Link color="secondary" component={RouterLink} to={`/data-lookup/bowser/organizations/${obj.id}`}>
          {obj.name}
        </Link>
      </Typography>
    ),
  } as SimpleTableColumn,
  {
    id: 'id',
    align: 'left',
    disablePadding: false,
    label: 'ID',
    sortable: true,
    getValue: obj => (
      <Typography>
        <Link color="secondary" component={RouterLink} to={`/data-lookup/bowser/organizations/${obj.id}`}>
          {obj.id}
        </Link>
      </Typography>
    ),
  } as SimpleTableColumn,
  {
    id: 'users',
    align: 'left',
    disablePadding: false,
    label: 'Users',
    sortable: false,
    getValue: obj => {
      return (
        <>
          <Box sx={{ width: '100%' }}>
            <Stack spacing={0}>
              {obj.organizationUsers.map(organizationUser => {
                let color;
                let title;
                switch (organizationUser.status) {
                  case 'active':
                  case 'accepted':
                    color = 'green';
                    title = 'Active';
                    break;
                  case 'pending':
                    color = 'primary';
                    title = 'Pending';
                    break;
                  default:
                    color = 'error';
                    title = 'Inactive';
                }
                return (
                  <div key={organizationUser.id}>
                    <div>
                      {/*<Link*/}
                      {/*  key={channel.id}*/}
                      {/*  color="secondary"*/}
                      {/*  component={RouterLink}*/}
                      {/*  to={`/data-lookup/bowser/channels/${channel.id}`}*/}
                      {/*>*/}
                      {/*  {channel.platformChannelName} / {channel.platformChannelId}*/}
                      {/*</Link>*/}

                      <Typography>
                        <Link
                          color="secondary"
                          component={RouterLink}
                          to={`/data-lookup/bowser/users/${organizationUser.userId}`}
                        >
                          {organizationUser.user.email} / {organizationUser.role} ({title}){' '}
                        </Link>
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </Stack>
          </Box>
        </>
      );
    },
  } as SimpleTableColumn,
  {
    id: 'channels',
    align: 'left',
    disablePadding: false,
    label: 'Channels',
    sortable: false,
    getValue: obj => {
      const entitlements = obj.organizationUsers.flatMap(organizationUser => organizationUser.user.entitlements);
      const seen = new Set();
      const uniqueEntitlements = entitlements.filter(entitlement => {
        const duplicate = seen.has(entitlement.channel.ucid);
        seen.add(entitlement.channel.ucid);
        return !duplicate;
      });

      const channels = uniqueEntitlements
        .filter(entitlement => entitlement.organizationId === obj.id)
        .map(entitlement => entitlement.channel);

      return (
        <>
          <Box sx={{ width: '100%' }}>
            <Stack spacing={0}>
              {channels.map(channel => {
                return (
                  <div key={channel.id}>
                    <div>
                      <Link
                        key={channel.id}
                        color="secondary"
                        component={RouterLink}
                        to={`/data-lookup/bowser/channels/${channel.id}`}
                      >
                        {channel.name} / {channel.ucid}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Stack>
          </Box>
        </>
      );
    },
  } as SimpleTableColumn,
  {
    id: 'active',
    align: 'left',
    sortable: false,
    disablePadding: true,
    label: 'Subscription Active',
    getValue: obj => {
      let color;
      let title;

      switch (obj.subscriptionActive) {
        case true:
          color = 'success';
          title = 'Yes';
          break;
        case false:
          color = 'error';
          title = 'No';
          break;
        default:
          color = 'primary';
          title = 'None';
      }

      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Dot color={color} />
          <Typography>{title}</Typography>
        </Stack>
      );
    },
  } as SimpleTableColumn,
  {
    id: 'billing',
    align: 'left',
    sortable: false,
    disablePadding: true,
    label: 'Billing Required',
    getValue: obj => {
      let color;
      let title;

      switch (obj.billingRequired) {
        case true:
          color = 'success';
          title = 'Yes';
          break;
        case false:
          color = 'error';
          title = 'No';
          break;
        default:
          color = 'primary';
          title = 'None';
      }

      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Dot color={color} />
          <Typography>{title}</Typography>
        </Stack>
      );
    },
  } as SimpleTableColumn,
  {
    id: 'assignedSeats',
    align: 'left',
    disablePadding: false,
    label: 'Assigned Seats',
    sortable: true,
    getValue: obj => obj.assignedSeats,
  } as SimpleTableColumn,
  {
    id: 'maxSeats',
    align: 'left',
    disablePadding: false,
    label: 'Max Seats',
    sortable: true,
    getValue: obj => obj.maxSeats,
  } as SimpleTableColumn,
  {
    id: 'assignedChannels',
    align: 'left',
    disablePadding: false,
    label: 'Assigned Channels',
    sortable: true,
    getValue: obj => obj.assignedChannels,
  } as SimpleTableColumn,
  {
    id: 'maxChannels',
    align: 'left',
    disablePadding: false,
    label: 'Max Channels',
    sortable: true,
    getValue: obj => obj.maxChannels,
  } as SimpleTableColumn,
];

const DataOrganizations = ({ customSearchFormValues, customColumns, customName, customValues }: CoreUITableProps) => {
  // const { filters, updateFilter } = useContext(FilterContext);
  const [filter, setFilter] = useState({ includeRollup: 1 });

  const theme = useTheme();
  const organizations = useSelector(state => state.organizations);

  const search = (values: any) => {
    setFilter({ ...values, includeRollup: 1 });
  };
  useEffect(() => {
    setFilter(customValues);
  }, [customValues]);

  return (
    <>
      <Grid container rowSpacing={2.5} columnSpacing={2.75}>
        {/* row 1 */}
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Typography variant="h5">
            Organizations&nbsp;
            <span style={{ color: theme.palette.text.secondary }}>({organizations.totalRecords})</span>
          </Typography>
        </Grid>
        {!customSearchFormValues && (
          <Grid item xs={12} md={12} lg={12}>
            <DashboardSearchTool
              loading={organizations.loading}
              onFormSubmit={search}
              formValues={customSearchFormValues}
            />
          </Grid>
        )}
        {/*</Grid>*/}
      </Grid>
      <Grid container rowSpacing={2.5} columnSpacing={2.75} sx={{ paddingTop: 1 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container alignItems="center" justifyContent="space-between"></Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <ReduxSimpleTable
              columns={customColumns ?? columns}
              sliceName={'organizations'}
              asyncThunk={fetchOrganizations}
              filters={filter}
            />
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DataOrganizations;
