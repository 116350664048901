// External Imports
import { AxiosInstance } from 'axios';
import _ from 'lodash';
import { APIOptions } from './types';

export const camelCaseKeys = (obj: Record<string, any>) => {
  return _.transform(obj, (result: Record<string, any>, value: any, key: string, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    result[camelKey] = _.isObject(value) ? camelCaseKeys(value as Record<string, any>) : value;
  });
};

export const snakeCaseKeys = (obj: Record<string, any> | undefined) => {
  if (!obj) {
    return null;
  }

  return _.transform(obj, (result: Record<string, any>, value: any, key: string, target) => {
    const camelKey = _.isArray(target) || key.includes('__') ? key : _.snakeCase(key);
    result[camelKey] = _.isObject(value) ? snakeCaseKeys(value as Record<string, any>) : value;
  });
};

export const concatQueryParams = (endpoint: string, params: Record<string, any> | undefined) => {
  let result = `${endpoint}?`;
  if (params) {
    _.forIn(params, (value, key) => {
      result = result + `${key}=${value}`;
      // Add & in between k/v if more than one element
      if (Object.keys(params)?.length > 1) {
        result = result + '&';
      }
    });
  }
  return result;
};

export const buildURL = (endpoint: string, params?: Record<string, any> | undefined | null) => {
  if (params) {
    // Normalize query params to snake_case for backend request to be accepted
    // params = snakeCaseKeys(params);
    // Construct the query params provided within options object
    return concatQueryParams(endpoint, { ...params });
  }
  return endpoint;
};

export class API {
  private endpoint: string;
  private successMessage: string | undefined;
  private errorMessage: string | undefined;
  private bannerMessageEnabled = false;
  private axiosInstance: AxiosInstance;

  constructor({ endpoint, axiosInstance, successMessage, errorMessage, bannerMessageEnabled }: APIOptions) {
    if (!endpoint.endsWith('/')) {
      endpoint = `${endpoint}/`;
    }
    this.endpoint = endpoint;
    this.successMessage = successMessage;
    this.errorMessage = errorMessage;
    this.bannerMessageEnabled = bannerMessageEnabled ? true : false;
    this.axiosInstance = axiosInstance;
  }

  private onError = (err: any, url: any, dispatch: any, rejectWithValue: any) => {
    if (!err.response) {
      throw err;
    }
    let message = JSON.stringify(err.response.data);
    if (this.errorMessage) {
      message = this.errorMessage;
    }
    console.error(`${url} - ${message}`);
    if (this.bannerMessageEnabled) {
      dispatch({ type: 'bannerMessage/errored', payload: message });
    }
    return rejectWithValue(err.response.data);
  };

  private onSuccess = (dispatch: any) => {
    if (this.successMessage && this.bannerMessageEnabled) {
      dispatch({ type: 'bannerMessage/success', payload: this.successMessage });
    }
  };

  fetchAll = async (dispatch: any, rejectWithValue: any, params?: Record<string, any>) => {
    const url = buildURL(this.endpoint, params);
    try {
      const response = await this.axiosInstance.get(url, params);
      if ('rollup' in response.data && response.data.rollup === null) {
        response.data.rollup = {};
      }
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err) {
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  fetchById = async (dispatch: any, rejectWithValue: any, id: string, params?: Record<string, any>) => {
    const url = buildURL(`${this.endpoint}${id}/`, params);
    try {
      const response = await this.axiosInstance.get(url, params);
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  fetch = async (dispatch: any, rejectWithValue: any, params?: Record<string, any>) => {
    const url = buildURL(this.endpoint, params);
    try {
      const response = await this.axiosInstance.get(url, params);
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  post = async (dispatch: any, rejectWithValue: any, data: Record<string, any>, params?: Record<string, any>) => {
    const url = buildURL(this.endpoint, params);
    try {
      const response = await this.axiosInstance.post(url, snakeCaseKeys(data));
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  put = async (
    dispatch: any,
    rejectWithValue: any,
    id: string,
    data: Record<string, any>,
    params?: Record<string, any>,
  ) => {
    const url = buildURL(`${this.endpoint}${id}/`, params);
    try {
      const response = await this.axiosInstance.put(url, snakeCaseKeys(data));
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  patch = async (
    dispatch: any,
    rejectWithValue: any,
    id: string,
    data: Record<string, any>,
    params?: Record<string, any>,
  ) => {
    const url = buildURL(`${this.endpoint}${id}/`, params);
    try {
      const response = await this.axiosInstance.patch(url, snakeCaseKeys(data));
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };

  delete = async (dispatch: any, rejectWithValue: any, id: string, params?: Record<string, any>) => {
    const url = buildURL(`${this.endpoint}${id}/`, params);
    try {
      const response = await this.axiosInstance.delete(url);
      this.onSuccess(dispatch);
      return camelCaseKeys(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return this.onError(err, url, dispatch, rejectWithValue);
    }
  };
}
