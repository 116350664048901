// Logout the current user by removing their session from localStorage
import { spotterAxios } from './spotterAxios';

export const spotterLogout = () => {
  spotterAxios.getInstance().get(`/auth0/logout-redirect/?returnTo=${window.location.origin}`);
};

// Determines if a user is logged in or not, will return false if the token within localstorage is expired
export const isLoggedIn = () => {
  return true;
};
