import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TableOutlined } from '@ant-design/icons';

interface MenuItem {
  id: string;
  title: string;
  type: string;
  url?: string;
  icon?: any;
  breadcrumbs?: boolean;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    id: 'bowser',
    title: 'Bowser',
    type: 'group',
    icon: 'https://play.nintendo.com/images/Masthead_Bowser.17345b1513ac044897cfc243542899dce541e8dc.9afde10b.png',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/data-lookup/bowser/users',
        icon: TableOutlined,
        breadcrumbs: true,
      },
      {
        id: 'channels',
        title: 'Channels',
        type: 'item',
        url: '/data-lookup/bowser/channels',
        icon: TableOutlined,
        breadcrumbs: true,
      },
      {
        id: 'organizations',
        title: 'Organizations',
        type: 'item',
        url: '/data-lookup/bowser/organizations',
        icon: TableOutlined,
        breadcrumbs: true,
      },
      {
        id: 'entitlements',
        title: 'Entitlements',
        type: 'item',
        url: '/data-lookup/bowser/entitlements',
        icon: TableOutlined,
        breadcrumbs: true,
      },
    ],
  },
  {
    id: 'kirby',
    title: 'Kirby',
    type: 'group',
    icon: 'https://play.nintendo.com/images/Masthead_Kirby.17345b1513ac044897cfc243542899dce541e8dc.9afde10b.png',
    children: [
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'item',
        url: '/data-lookup/kirby/tasks',
        icon: TableOutlined,
        breadcrumbs: true,
      },
      {
        id: 'channels',
        title: 'Channels',
        type: 'item',
        url: '/data-lookup/kirby/channels',
        icon: TableOutlined,
        breadcrumbs: true,
      },
      {
        id: 'executions',
        title: 'Executions',
        type: 'item',
        url: '/data-lookup/kirby/executions',
        icon: TableOutlined,
        breadcrumbs: true,
      },
    ],
  },
  {
    id: 'lakitu',
    title: 'Lakitu',
    icon: 'https://play.nintendo.com/images/Masthead_Koopa.17345b1513ac044897cfc243542899dce541e8dc.9afde10b.png',
    type: 'group',
    children: [],
  },
  {
    id: 'rainbowRoad',
    title: 'Rainbow Road',
    type: 'group',
    icon: 'https://depauliaonline.com/wp-content/uploads/2019/10/mariokart-577x900.png',
    children: [
      {
        id: 'allServices',
        title: 'All Services',
        type: 'item',
        url: '/data-lookup/rainbow-road/channels',
        icon: TableOutlined,
        breadcrumbs: true,
      },
    ],
  },
  // {
  //   id: 'shyguy',
  //   title: 'Shy Guy',
  //   icon: 'https://play.nintendo.com/images/Masthead_ShyGuy.17345b1513ac044897cfc243542899dce541e8dc.9afde10b.png',
  //   type: 'group',
  //   children: [],
  // },
];

const Navigation = () => {
  return (
    <Box sx={{ pt: 2 }}>
      {menuItems.map(item => (
        <Accordion key={item.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <img
              src={item.icon}
              alt="Icon"
              style={{
                marginRight: '0.5rem',
                verticalAlign: 'middle',
                width: '2rem',
                height: '2rem',
              }}
            />
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List component="nav">
              {item.children &&
                item.children.map(child => (
                  <ListItem button component="a" href={child.url} key={child.id}>
                    <ListItemIcon>{React.createElement(child.icon)}</ListItemIcon>
                    <ListItemText primary={child.title} />
                  </ListItem>
                ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Navigation;
