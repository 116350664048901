// External Imports
import { combineReducers } from 'redux';

// Internal Imports
import menu from './slices/menu';
import fetchAllChannels from './slices/bowser/channels/fetchAll';
import fetchChannelById from './slices/bowser/channels/fetchById';
import fetchAllUsers from './slices/bowser/users/fetchAll';
import fetchUserById from './slices/bowser/users/fetchById';
import getUserProfile from './slices/bowser/users/getProfile';
import oAuthAreaChart from './slices/metrics/oAuthAreaChart';
import bannerMessage from './slices/bannerMessage';
import fetchAllOrganizations from './slices/bowser/organizations/fetchAll';
import fetchOrganizationById from './slices/bowser/organizations/fetchById';
import fetchUserActivity from './slices/metrics/userActivityMetrics';
import fetchSevenDayUserActivity from './slices/metrics/sevenDayUserActivityMetrics';
import fetchAllKirbyTasks from './slices/kirby/tasks/fetchAll';
import fetchKirbyTaskById from './slices/kirby/tasks/fetchById';
import fetchAllEntitlements from './slices/bowser/entitlements/fetchAll';
import fetchEntitlementById from './slices/bowser/entitlements/fetchById';

import fetchAllExecutions from './slices/kirby/executions/fetchAll';

import fetchAllKirbyChannels from './slices/kirby/channels/fetchAll';
import fetchKirbyChannelById from './slices/kirby/channels/fetchById';
import fetchVideoProjectsByOrgIdAndUcid from './slices/lakitu/video-projects/fetchVideoProjectsByOrgIdAndChannelUcid';
import fetchSavedItemsByOrgIdAndUcid from './slices/lakitu/saved-items/fetchSavedItemsByOrgIdAndChannelUcid';
import fetchCastMembersByOrgIdAndChannelUcid from './slices/lakitu/cast-members/fetchCastMembersByOrgIdAndChannelUcid';

// Any new reducers created should be added here so we have access to the
// data needed to the React layer
const reducers = combineReducers({
  // Portal
  channels: fetchAllChannels,
  channel: fetchChannelById,
  users: fetchAllUsers,
  user: fetchUserById,
  userProfile: getUserProfile,
  oAuthAreaChart: oAuthAreaChart,
  bannerMessage: bannerMessage,
  userActivity: fetchUserActivity,
  sevenDayUserActivity: fetchSevenDayUserActivity,
  organizations: fetchAllOrganizations,
  organization: fetchOrganizationById,
  entitlements: fetchAllEntitlements,
  entitlement: fetchEntitlementById,
  kirbyTasks: fetchAllKirbyTasks,
  kirbyTask: fetchKirbyTaskById,
  kirbyChannels: fetchAllKirbyChannels,
  kirbyChannel: fetchKirbyChannelById,
  executions: fetchAllExecutions,
  videoProjects: fetchVideoProjectsByOrgIdAndUcid,
  savedItems: fetchSavedItemsByOrgIdAndUcid,
  castMembers: fetchCastMembersByOrgIdAndChannelUcid,
  menu,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
