import { useEffect, useState } from 'react';
import { Outlet, Navigate, useSearchParams, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

import Header from './Header';

import { openDrawer } from '../../store/slices/menu';
import Drawer from './Drawer';
import { useDispatch, useSelector } from '../../store/hooks';
import { getProfile } from '../../store/slices/bowser/users/getProfile';

import jwt_decode from 'jwt-decode';

import { isLoggedIn, spotterLogout } from '../../lib/core/authentication/spotterAuthentication';
import { DecodedToken } from '../../lib/core/authentication/spotterJwtToken';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const dispatch = useDispatch();
  const appDispatch = useDispatch();
  const navigate = useNavigate();

  const { drawerOpen } = useSelector((state: any) => state.menu);
  const userProfile = useSelector((state: any) => state.userProfile);

  const [searchParams, setSearchParams] = useSearchParams();

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG]);

  useEffect(() => {
    const token = searchParams.get('access_token');
    const refreshToken = searchParams.get('refresh_token');
    searchParams.delete('access_token');
    searchParams.delete('refresh_token');
    searchParams.delete('id_token');
    searchParams.delete('scope');
    searchParams.delete('token_type');
    searchParams.delete('expires_in');
    setSearchParams(searchParams);

    if (token && refreshToken) {
      const decodedToken: DecodedToken = jwt_decode(token);

      if (!decodedToken.permissions.includes('admin') && !decodedToken.permissions.includes('internal')) {
        navigate('/login');
      }
    }

    // Only fetches it on login, do something later to update on refresh of token
    if (isLoggedIn()) {
      appDispatch(getProfile());
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    if (userProfile.data?.permissions) {
      if (!userProfile.data.permissions.includes('admin') && !userProfile.data.permissions.includes('internal')) {
        navigate('/login');
      }
    }
  }, [userProfile]);

  if (!isLoggedIn()) {
    return <Navigate to="/login" />;
  } else {
    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        {userProfile.loading && <></>}
        {userProfile.errored && <div>Error Placeholder</div>}
        {userProfile.fulfilled && (
          <>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
              <Toolbar />
              <Outlet />
            </Box>
          </>
        )}
      </Box>
    );
  }
};

export default MainLayout;
