import { createSlice, createAsyncThunk, combineReducers } from '@reduxjs/toolkit';

import { API } from '../../../../lib/core/api/API';
import { FetchAllState, PaginationPages } from '../../../../lib/core/api/types';
import { spotterAxios } from '../../../../lib/core/authentication/spotterAxios';

const name = 'kirbyChannels';

export const fetchChannels = createAsyncThunk(
  name,
  async (params: Record<string, any>, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const api = new API({
      endpoint: '/channels/',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });

    if (params.statistic) {
      //filters=(platform_channel_statistics__video_count__gt=100)  %26 (platform_channel_statistics__subscriber_count__gt=10000)
      let filterString = '';
      params.statistic.forEach((statistic, index) => {
        if (statistic.operator == 'eq') {
          filterString += `(platform_channel_statistics__${statistic.comparisonKey}=${statistic.comparisonValue})`;
        } else {
          filterString += `(platform_channel_statistics__${statistic.comparisonKey}__${statistic.operator}=${statistic.comparisonValue})`;
        }

        if (index != params.statistic.length - 1) {
          filterString += '%26';
        }
      });
      params['filters'] = filterString;
      delete params.statistic;
    }

    return api.fetchAll(dispatch, rejectWithValue, params);
  },
);

export const channels = createSlice({
  name: name,
  initialState: {
    results: [],
    rollup: {},
    pages: {
      totalPages: 0,
      pageSize: 0,
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      startingRecord: 0,
      endingRecord: 0,
      totalRecords: 0,
    } as PaginationPages,
    loading: false,
    errored: false,
    fulfilled: false,
    totalRecords: 0,
  } as FetchAllState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchChannels.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(fetchChannels.rejected, (state, action) => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(fetchChannels.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.pages = action.payload.pages;
      state.results = action.payload.results;
      state.rollup = action.payload.rollup;
      state.totalRecords = action.payload.totalRecords;
    });
  },
});

export default channels.reducer;
