// External Imports
import { createRoot } from 'react-dom/client';

// Internal Imports
import App from './App';

// Render the React App Component where the tag with id 'root' is located within the
// public/index.html file
const rootElement = document.querySelector('#root');

if (!rootElement) {
  throw new Error('Unable to find root element to render React application');
}

const root = createRoot(rootElement);
root.render(<App />);
