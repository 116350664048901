import moment from 'moment';

export const toBoolean = (input: string) => {
  return ['true', 'True', 'TRUE', '1', 't'].includes(input);
};

export const rotateArray = function (input: Array<any>, k: number) {
  for (let i = 0; i < k; i++) {
    input.unshift(input.pop());
  }

  return input;
};

export const hours = (rotate = true) => {
  // Frontend - 0 is midnight
  const hours_ = Array(24)
    .fill(0)
    .map((x, index) => index.toString());
  const currentHour = new Date().getHours();
  if (rotate) {
    rotateArray(hours_, hours_.length - 1 - currentHour);
  }
  return hours_;
};

export const daysInWeek = (rotate = true) => {
  // Backend  - Sunday is 1 and Saturday is 7.
  // Frontend - Sunday is 0 and Saturday is 6.
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const today = new Date().getDay();
  if (rotate) {
    rotateArray(days, days.length - 1 - today);
  }

  return days;
};

export const months = (rotate = true) => {
  // Backend  - January is 1 and December is 12.
  // Frontend - January is 0 and December is 11.
  const this_month = new Date().getMonth();
  const months_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (rotate) {
    rotateArray(months_list, months_list.length - 1 - this_month);
  }
  return months_list;
};

export const getYearsList = (pastYears = 5) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  let startYear = currentYear - pastYears;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  rotateArray(years, years.length - 1 - currentYear);
  return years;
};

export const getListOfDates = (since: number) => {
  return [...new Array(since)]
    .map((i, idx) => moment().startOf('day').subtract(idx, 'days').format('YYYY-MM-DD'))
    .reverse();
};

export const utcToLocalDate = (dateString: string) => {
  const d = new Date(dateString);
  const timeZone = d.toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()} ${timeZone}`;
};

export const dedupJsonArray = (array: [Record<string, unknown>]) =>
  array.filter((obj, index, self) => index === self.findIndex(i => i.value === obj.value));
