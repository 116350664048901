type HttpMethod = 'GET' | 'POST';

type RequestOptions = Record<string, unknown>;

function handleErrors(res: Response) {
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error('Unauthorized');
    }
    throw new Error(`${res.status} ${res.statusText}`);
  }
}

class ApiService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new Error('Missing baseUrl in ApiService');
    }
    this.baseUrl = baseUrl;
  }

  private async request(method: HttpMethod, pathname: string, options?: RequestOptions): Promise<Response> {
    const url = `${this.baseUrl}${pathname}`;
    const body = options?.data ? JSON.stringify(options.data) : null;
    const config: RequestInit = {
      method,
      credentials: 'include',
      body,
      ...options,
    };

    if (method === 'GET') {
      delete config.body;
    }

    const response = await fetch(url, config);
    return response;
  }

  async get<T>(pathname: string, options?: RequestOptions): Promise<T> {
    let location = pathname;

    if (options) {
      location += '?' + new URLSearchParams(options as Record<string, string>).toString();
    }

    const req = await this.request('GET', location);

    handleErrors(req);

    try {
      const data = await req.json();
      return data as T;
    } catch (err) {
      return null as T;
    }
  }

  async post<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('POST', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }
}

class Api {
  connect: ApiService;
  // relayRaptor: ApiService;
  // auth0: ApiService;

  // prettier-ignore
  constructor() {
    this.connect = new ApiService(process.env.REACT_APP_BACKEND_BOWSER_API_CONTEXT_ROOT);
    // this.relayRaptor = new ApiService(import.meta.env.VITE_RELAY_RAPTOR_API_URL);
    // this.auth0 = new ApiService(import.meta.env.VITE_AUTH0_API_URL)
  }
}

export const api = new Api();
