// React/Redux Imports
import { configureStore } from '@reduxjs/toolkit';

// Internal Imports
import reducers from './reducers';

// Configure store, will automaticaly call applyMiddleware and devToolsExtension
// For more info see: https://redux-toolkit.js.org/api/configureStore
export const store = configureStore({
  reducer: reducers,
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
