// material-ui
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../store/hooks';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';

// project import
import { LoadingBar } from '../loadingBar';
import { FetchAllState } from '../../lib/core/api/types';

interface GetCellValue {
  (obj: any): any;
}
type Order = 'asc' | 'desc';

export interface SimpleTableColumn {
  id: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  disablePadding: boolean;
  label: string | React.ReactElement;
  sortable: boolean;
  getValue: GetCellValue;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  columns: SimpleTableColumn[];
  selectableCheckboxRowsEnabled: boolean;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const renderCell = (headCell: SimpleTableColumn) => {
    return headCell.sortable ? (
      <TableCell
        key={headCell.id}
        padding={headCell.disablePadding ? 'none' : 'normal'}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <Box component="span" sx={visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    ) : (
      <TableCell key={`${headCell.id}`} align={headCell.align}>
        {headCell.label}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {props.selectableCheckboxRowsEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < props.rowCount}
              checked={props.rowCount > 0 && numSelected === props.rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {props.columns.map((headCell: SimpleTableColumn) => renderCell(headCell))}
      </TableRow>
    </TableHead>
  );
}

const SimpleTableHead = ({ columns }: { columns: SimpleTableColumn[] }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell: SimpleTableColumn) => (
          <TableCell
            key={headCell.id}
            align={headCell.align as any}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface FetchDataProps {
  count: string;
  page: string;
}

export const ReduxSimpleTable = ({
  columns,
  sliceName,
  asyncThunk,
  filters,
  selectableCheckboxRowsEnabled,
  onChangeSelectedCheckboxRows,
  selectableRowEnabled,
  onChangeSelectedRow,
}: {
  columns: SimpleTableColumn[];
  sliceName: string;
  asyncThunk: any;
  filters: any;
  selectableCheckboxRowsEnabled?: boolean;
  onChangeSelectedCheckboxRows?: (rows: any[]) => void;
  selectableRowEnabled?: boolean;
  onChangeSelectedRow?: (row: any) => void;
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filtersObject, setFiltersObject] = useState({ ...filters });
  const [sortProperty, setSortProperty] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const slice = useSelector<FetchAllState>(state => state[sliceName]);

  const dispatchAPICall = () => {
    const data = {
      pageSize: rowsPerPage.toString(),
      page: (currentPage + 1).toString(),
      ...filtersObject,
    };
    if (sortProperty) {
      data.sort = sortProperty;
    }
    dispatch(asyncThunk(data));
  };

  useEffect(() => {
    // Prevents misfiring of API calls
    if (!_.isEqual(filters, filtersObject)) {
      // Set page to 0 when filters change
      setCurrentPage(0);
      setFiltersObject({ ...filters });
    }
  }, [filters]);

  useEffect(() => {
    dispatchAPICall();
  }, [filtersObject, rowsPerPage, currentPage, sortProperty]);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSortProperty(isAsc ? '-' + property : property);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  return (
    <>
      <TablePagination
        component="div"
        sx={{
          display: 'flex',
          backgroundColor: theme.palette.background.default,
        }}
        count={slice.totalRecords}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          disabled: slice.loading,
        }}
        backIconButtonProps={
          slice.loading
            ? {
                disabled: slice.loading,
              }
            : undefined
        }
        nextIconButtonProps={
          slice.loading
            ? {
                disabled: slice.loading,
              }
            : undefined
        }
      />
      <LoadingBar loading={slice.loading} />
      <SortableTable
        columns={columns}
        data={slice.results}
        handleSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        selectableCheckboxRowsEnabled={selectableCheckboxRowsEnabled}
        selectableRowEnabled={selectableRowEnabled}
        onChangeSelectedCheckboxRows={onChangeSelectedCheckboxRows}
        onChangeSelectedRow={row => onChangeSelectedRow?.(row)}
      />
    </>
  );
};

export function SortableTable({
  columns,
  data,
  handleSort,
  order,
  orderBy,
  selectableCheckboxRowsEnabled,
  onChangeSelectedCheckboxRows,
  selectableRowEnabled,
  onChangeSelectedRow,
}: {
  columns: SimpleTableColumn[];
  data: any;
  handleSort;
  order;
  orderBy;
  selectableCheckboxRowsEnabled?: boolean;
  onChangeSelectedCheckboxRows?: (rows: any[]) => void;
  selectableRowEnabled?: boolean;
  onChangeSelectedRow?: (row: any) => void;
}) {
  const [selected, setSelected] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    onChangeSelectedCheckboxRows?.(selected);
  }, [selected]);

  useEffect(() => {
    onChangeSelectedRow?.(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    setSelected([]);
    setSelectedRow(null);
    setTableData([...data]);
  }, [data]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected([...tableData]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, obj: Record<string, unknown>) => {
    const index = selected.findIndex(x => x.id === obj.id);
    if (index > -1) {
      // Remove if exists
      selected.splice(index, 1);
      setSelected([...selected]);
    } else {
      // Add if not in there
      setSelected(prev => [...prev, obj]);
    }
  };

  const getTableRow = (row: any, index: any, key: any) => {
    if (selectableCheckboxRowsEnabled) {
      const isSelectedCheckbox = (obj: Record<string, unknown>) => selected.find(x => x.id === obj.id) != undefined;
      const isItemSelected = isSelectedCheckbox(row);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          hover
          sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
          tabIndex={-1}
          onClick={event => handleClick(event, row)}
          role="checkbox"
          aria-checked={isItemSelected}
          selected={isItemSelected}
          key={key}
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          {columns.map((col: SimpleTableColumn) => (
            <TableCell key={`${key}-${col.id}`} align={col.align}>
              {col.getValue(row)}
            </TableCell>
          ))}
        </TableRow>
      );
    } else if (selectableRowEnabled) {
      const isSelectedRow = (obj: Record<string, unknown>) => selectedRow?.id == obj.id;
      return (
        <TableRow
          hover
          sx={{
            cursor: 'pointer',
            '&:last-child td, &:last-child th': { border: 0 },
            '&:hover': {
              background: 'grey !important',
            },
            background: isSelectedRow(row) ? 'grey' : 'inherit',
          }}
          tabIndex={-1}
          key={key}
          onClick={e => {
            if (row.id == selectedRow?.id) {
              setSelectedRow(null);
            } else {
              setSelectedRow(row);
            }
          }}
        >
          {columns.map((col: SimpleTableColumn) => (
            <TableCell key={`${key}-${col.id}`} align={col.align}>
              {col.getValue(row)}
            </TableCell>
          ))}
        </TableRow>
      );
    }

    return (
      <TableRow
        hover
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        tabIndex={-1}
        key={key}
      >
        {columns.map((col: SimpleTableColumn) => (
          <TableCell key={`${key}-${col.id}`} align={col.align}>
            {col.getValue(row)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          '& td, & th': { whiteSpace: 'nowrap' },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            '& .MuiTableCell-root:first-of-type': {
              pl: 2,
            },
            '& .MuiTableCell-root:last-child': {
              pr: 3,
            },
          }}
        >
          <EnhancedTableHead
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleSort}
            selectableCheckboxRowsEnabled={selectableCheckboxRowsEnabled}
            rowCount={tableData.length}
          />
          <TableBody>
            {tableData.map((row, index) => {
              let key = row.id;
              if (!key) {
                key = index;
              }
              return getTableRow(row, index, key);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function SimpleTable({ columns, data }: { columns: SimpleTableColumn[]; data: any }) {
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          '& td, & th': { whiteSpace: 'nowrap' },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            '& .MuiTableCell-root:first-of-type': {
              pl: 2,
            },
            '& .MuiTableCell-root:last-child': {
              pr: 3,
            },
          }}
        >
          <SimpleTableHead columns={columns} />
          <TableBody>
            {data.map((row, index) => {
              let key = row.id;
              if (!key) {
                key = index;
              }
              return (
                <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }} tabIndex={-1} key={key}>
                  {columns.map((col: SimpleTableColumn) => (
                    <TableCell key={`${key}-${col.id}`} align={col.align}>
                      {col.getValue(row)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
