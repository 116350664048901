import { type ReactNode } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth0Client, Auth0Provider } from '../lib/auth0';

export function AppProviders({ children }: { children: ReactNode }) {
  const auth0Client = new Auth0Client({
    redirectUrl: `${window.location.origin}`,
    connectUrl: process.env.REACT_APP_BACKEND_BOWSER_API_CONTEXT_ROOT,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    backendRedirectUrl: process.env.REACT_APP_AUTH_BACKEND_REDIRECT_URL,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
  });
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: async err => {
        if (err.message === 'Unauthorized') {
          try {
            await auth0Client.getAccessTokenSilently();
          } catch {
            await auth0Client.logout();
            window.location.href = '/';
          }
        } else {
          throw new Error(err.message);
        }
      },
    }),
  });
  return (
    <Auth0Provider client={auth0Client}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Auth0Provider>
  );
}
