import { lazy } from 'react';

// project import
import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../lib/core/routing/Loadable';

// // render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />,
    },
  ],
};

export default LoginRoutes;
