import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import DataOrganizations from '../pages/data-lookup/bowser/organizations/organizations';
import Loadable from '../lib/core/routing/Loadable';
import CatSign from '../pages/cat';

const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/index')));
// Kirby
const DataTasks = Loadable(lazy(() => import('../pages/data-lookup/kirby/tasks/tasks')));
const DataTaskDetail = Loadable(lazy(() => import('../pages/data-lookup/kirby/tasks/taskDetail')));
const DataKirbyChannels = Loadable(lazy(() => import('../pages/data-lookup/kirby/channels/channels')));
const DataRainbowRoadChannels = Loadable(lazy(() => import('../pages/data-lookup/rainbow-road/channels/channels')));
const DataKirbyChannelDetail = Loadable(lazy(() => import('../pages/data-lookup/kirby/channels/channelDetail')));
const DataKirbyExecutions = Loadable(lazy(() => import('../pages/data-lookup/kirby/executions/executions')));

// Bowser
const DataChannels = Loadable(lazy(() => import('../pages/data-lookup/bowser/channels/channels')));
const DataEntitlements = Loadable(lazy(() => import('../pages/data-lookup/bowser/entitlements/entitlements')));
const DataEntitlementDetail = Loadable(
  lazy(() => import('../pages/data-lookup/bowser/entitlements/entitlementDetail')),
);
const DataChannelDetail = Loadable(lazy(() => import('../pages/data-lookup/bowser/channels/channelDetail')));
const DataUsers = Loadable(lazy(() => import('../pages/data-lookup/bowser/users/users')));
const DataUserDetail = Loadable(lazy(() => import('../pages/data-lookup/bowser/users/userDetail')));
const DataOrganizationDetail = Loadable(
  lazy(() => import('../pages/data-lookup/bowser/organizations/organizationDetail')),
);
const SelfServiceValidateTokens = Loadable(lazy(() => import('../pages/self-service/validateTokens')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      children: [
        {
          path: '',
          element: <DashboardDefault />,
        },
        // {
        //   path: '',
        //   element: <CatSign />,
        // },
      ],
    },
    {
      path: 'data-lookup/bowser',
      children: [
        {
          path: 'channels',
          children: [
            { index: true, element: <DataChannels /> },
            { path: ':id', element: <DataChannelDetail /> },
          ],
        },
        {
          path: 'users',
          children: [
            { index: true, element: <DataUsers /> },
            { path: ':id', element: <DataUserDetail /> },
          ],
        },
        {
          path: 'organizations',
          children: [
            { index: true, element: <DataOrganizations /> },
            { path: ':id', element: <DataOrganizationDetail /> },
          ],
        },
        {
          path: 'entitlements',
          children: [
            { index: true, element: <DataEntitlements /> },
            { path: ':id', element: <DataEntitlementDetail /> },
          ],
        },
      ],
    },
    {
      path: 'data-lookup/kirby',
      children: [
        {
          path: 'tasks',
          children: [
            { index: true, element: <DataTasks /> },
            { path: ':id', element: <DataTaskDetail /> },
          ],
        },
        {
          path: 'channels',
          children: [
            { index: true, element: <DataKirbyChannels /> },
            { path: ':id', element: <DataKirbyChannelDetail /> },
          ],
        },
        {
          path: 'executions',
          children: [{ index: true, element: <DataKirbyExecutions /> }],
        },
      ],
    },

    {
      path: 'data-lookup/rainbow-road',
      children: [
        {
          path: 'channels',
          children: [{ index: true, element: <DataRainbowRoadChannels /> }],
        },
      ],
    },
    {
      path: 'self-service',
      children: [
        {
          path: 'validate-tokens',
          element: <SelfServiceValidateTokens />,
        },
      ],
    },
    {
      path: '*',
      element: <DashboardDefault />,
    },
  ],
};

export default MainRoutes;
