import { createSlice } from '@reduxjs/toolkit';
import { BannerMessage } from '../../components/BannerMessage';
import { v4 as uuidv4 } from 'uuid';

export const bannerMessage = createSlice({
  name: 'bannerMessage',
  initialState: {
    message: '',
    status: 'success',
    id: null,
  } as BannerMessage,

  reducers: {
    success(state, action) {
      state.id = uuidv4();
      state.message = action.payload;
      state.status = 'success';
    },
    errored(state, action) {
      state.id = uuidv4();
      state.message = action.payload;
      state.status = 'error';
    },

    warning(state, action) {
      state.id = uuidv4();
      state.message = action.payload;
      state.status = 'warning';
    },

    info(state, action) {
      state.id = uuidv4();
      state.message = action.payload;
      state.status = 'info';
    },
  },
});

export default bannerMessage.reducer;
