import { FieldRenderProps, FieldMetaState } from 'react-final-form';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import theme from '../../style/muiTheme';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

export const SearchTextField: React.FunctionComponent<FieldRenderProps<string, HTMLElement>> = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [toggler, setToggler] = useState<boolean>(false);
  const errorMessage = (meta: FieldMetaState<string>) => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
  };
  useEffect(() => {
    if (initialized) {
      setToggler(prev => !prev);
    } else {
      setInitialized(true);
    }
  }, [rest.resetToggle]);

  const setInputProps = () => {
    if (rest.disabled) {
      return { ...rest.inputProps, readOnly: true, disabled: true, sx: { color: 'grey' } };
    }
    return rest.inputProps;
  };

  return (
    <>
      {toggler && (
        <TextField
          id={rest?.id}
          label={rest?.label}
          variant="outlined"
          onChange={input?.onChange}
          style={rest?.style}
          helperText={errorMessage(meta)}
          onBlur={event => input?.onBlur(event)}
          error={meta.touched && meta.invalid}
          inputProps={setInputProps()}
          defaultValue={input.value}
        />
      )}
      {!toggler && (
        <TextField
          id={rest?.id}
          label={rest?.label}
          variant="outlined"
          onChange={input?.onChange}
          style={rest?.style}
          helperText={errorMessage(meta)}
          onBlur={event => input?.onBlur(event)}
          error={meta.touched && meta.invalid}
          inputProps={setInputProps()}
          defaultValue={input.value}
        />
      )}
    </>
  );
};

export const SearchDropdownField: React.FunctionComponent<FieldRenderProps<string, HTMLElement>> = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) => {
  const [value, setValue] = React.useState(rest.retainValue ? rest.retainValue : input.value);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    input?.onChange(event.target.value);
  };

  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (initialized) {
      setValue('');
    } else {
      setInitialized(true);
    }
  }, [rest.resetToggle]);

  interface dropdownOption {
    key: string;
    value: string;
  }
  const renderOptions = (options: dropdownOption[]) => {
    const menuOptions = [];
    options.forEach(element => {
      menuOptions.push(
        <MenuItem key={element.key} value={element.key}>
          {element.value}
        </MenuItem>,
      );
    });

    return menuOptions;
  };

  return (
    <FormControl sx={{ minWidth: 140, ...rest?.style }}>
      <InputLabel id={rest.id + '-label'}>{rest.label}</InputLabel>
      <Select
        labelId={rest.id + '-label'}
        id={rest.id}
        value={value}
        label={rest.label}
        onChange={handleChange}
        error={meta.invalid && meta.touched}
        sx={{ minHeight: 20 }}
      >
        {renderOptions(rest.dropDownOptions)}
      </Select>
    </FormControl>
  );
};

export const SearchDatePickerField: React.FunctionComponent<FieldRenderProps<string, HTMLElement>> = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) => {
  const [value, setValue] = React.useState<Dayjs | string | null>(rest.retainValue ? rest.retainValue : null);
  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    if (initialized) {
      setValue(null);
    } else {
      setInitialized(true);
    }
  }, [rest.resetToggle]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <span style={rest?.style}>
        <DatePicker
          label={rest.label}
          value={value}
          onChange={(newValue: Dayjs) => {
            const date = newValue.toISOString().split('T')[0];
            setValue(date);
            input?.onChange(date);
          }}
          renderInput={params => <TextField {...params} />}
        />
      </span>
    </LocalizationProvider>
  );
};

export const SearchNumberField: React.FunctionComponent<FieldRenderProps<string, HTMLElement>> = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, HTMLElement>) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [toggler, setToggler] = useState<boolean>(false);
  useEffect(() => {
    if (initialized) {
      setToggler(prev => !prev);
    } else {
      setInitialized(true);
    }
  }, [rest.resetToggle]);

  return (
    <>
      <FormControl sx={{ minWidth: 140, ...rest?.style }}>
        <TextField
          id={rest?.id}
          label={rest?.label}
          value={input.value}
          variant="outlined"
          onChange={input?.onChange}
          type="number"
          error={meta.invalid && meta.touched}
          InputProps={rest?.InputProps}
        />
      </FormControl>
    </>
  );
};
