import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { OAuthAreaChart } from './types';
import { API } from '../../../lib/core/api/API';
import { spotterAxios } from '../../../lib/core/authentication/spotterAxios';
import { FetchObjectState } from '../../../lib/core/api/types';
import {
  daysInWeek,
  getListOfDates,
  getYearsList,
  hours,
  months,
  rotateArray,
  toBoolean,
} from '../../../lib/core/utils';

const name = 'oauthAreaChart';

interface FetchOAuthChartParams {
  timeframe: string;
  rotateData: boolean;
  within?: number;
}

export const getOAuthAreaChart = createAsyncThunk(
  name,
  async (params: FetchOAuthChartParams, { dispatch, getState, rejectWithValue, fulfillWithValue }: any) => {
    const api = new API({
      endpoint: '/metrics/oauth-rollup/',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });
    return api.fetch(dispatch, rejectWithValue, params);
  },
);

export const oAuthAreaChart = createSlice({
  name: name,
  initialState: {
    data: {
      params: {},
      results: [],
    },
    loading: false,
    errored: false,
    fulfilled: false,
  } as FetchObjectState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOAuthAreaChart.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(getOAuthAreaChart.rejected, state => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(getOAuthAreaChart.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;

      const data = {
        legend: [],
        monetized: [],
        unmonetized: [],
      } as OAuthAreaChart;

      let timespan = [];
      const hour = new Date().getHours();
      const today = new Date().getDay();
      const this_month = new Date().getMonth();
      const this_year = new Date().getFullYear();

      const initialData = action.payload.results;
      const { within, timeframe } = action.payload.params;
      let { rotateData } = action.payload.params;
      rotateData = toBoolean(rotateData);
      switch (timeframe) {
        case 'date':
          timespan = getListOfDates(+within);
          data.monetized = Array(timespan.length).fill(0);
          data.unmonetized = Array(timespan.length).fill(0);
          initialData.forEach(entry => {
            const index = timespan.findIndex(x => x == entry.date);
            if (entry.monetized) {
              data.monetized[index] = entry.count;
            } else {
              data.unmonetized[index] = entry.count;
            }
          });
          break;
        case 'hourly':
          timespan = hours(!rotateData);
          data.monetized = Array(timespan.length).fill(0);
          data.unmonetized = Array(timespan.length).fill(0);
          initialData.forEach(entry => {
            const index = entry.hourly;
            if (entry.monetized) {
              data.monetized[index] = entry.count;
            } else {
              data.unmonetized[index] = entry.count;
            }
          });
          if (!rotateData) {
            rotateArray(data.monetized, 23 - hour);
            rotateArray(data.unmonetized, 23 - hour);
          }
          break;
        case 'weekday':
          // Backend  - Sunday is 1 and Saturday is 7
          // Frontend - Sunday is 0 and Saturday is 6.
          timespan = daysInWeek(!rotateData);
          data.monetized = Array(timespan.length).fill(0);
          data.unmonetized = Array(timespan.length).fill(0);
          initialData.forEach(entry => {
            const index = entry.weekday - 1; // to get in line with difference of index
            if (entry.monetized) {
              data.monetized[index] = entry.count;
            } else {
              data.unmonetized[index] = entry.count;
            }
          });
          if (!rotateData) {
            rotateArray(data.monetized, 6 - today);
            rotateArray(data.unmonetized, 6 - today);
          }
          break;
        case 'monthly':
          // Backend  - January is 1 and December is 12.
          // Frontend - January is 0 and December is 11.
          timespan = months(!rotateData);
          data.monetized = Array(timespan.length).fill(0);
          data.unmonetized = Array(timespan.length).fill(0);
          initialData.forEach(entry => {
            const index = entry.monthly - 1; // to get in line with difference of index
            if (entry.monetized) {
              data.monetized[index] = entry.count;
            } else {
              data.unmonetized[index] = entry.count;
            }
          });
          if (!rotateData) {
            rotateArray(data.monetized, 11 - this_month);
            rotateArray(data.unmonetized, 11 - this_month);
          }
          break;
        case 'annually':
          timespan = getYearsList(3);
          data.monetized = Array(timespan.length).fill(0);
          data.unmonetized = Array(timespan.length).fill(0);
          initialData.forEach(entry => {
            const index = timespan.findIndex(x => x == entry.annually);
            if (entry.monetized) {
              data.monetized[index] = entry.count;
            } else {
              data.unmonetized[index] = entry.count;
            }
          });
          if (!rotateData) {
            const offset = timespan.length - 1 - this_year;
            rotateArray(data.monetized, offset);
            rotateArray(data.unmonetized, offset);
          }
          break;
        default:
          throw Error('invalid timeframe');
      }

      state.data = data;
    });
  },
});

export default oAuthAreaChart.reducer;
