import { Box, LinearProgress } from '@mui/material';

export const LoadingBar = ({ loading, noWhiteSpace }: { loading: boolean; noWhiteSpace?: boolean }) => {
  return (
    <Box sx={{ width: '100%' }}>
      {loading && <LinearProgress />}
      {!loading && !noWhiteSpace && <div style={{ height: '6px' }} />}
    </Box>
  );
};
