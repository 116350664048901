import React, { useContext } from 'react';
import { api } from './fetch';

export type Auth0SuccessResponse = {
  success: string;
};

export type Auth0UserProfile = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  studio_user_channel_access: Array<{
    role: string;
    channel: {
      platform_channel_thumbnail: string;
      platform_channel_name: string;
    };
  }>;
};

export type Auth0User = {
  email: string;
  profile: Auth0UserProfile;
};

export type Auth0ClientConfig = {
  domain: string;
  connectUrl: string;
  clientId: string;
  redirectUrl: string;
  backendRedirectUrl: string;
  audience: string;
};

export class Auth0Client {
  private domain: string;
  private connectUrl: string;
  private clientId: string;
  private redirectUrl: string;
  private backendRedirectUrl: string;
  private audience: string;

  constructor({ domain, connectUrl, clientId, redirectUrl, backendRedirectUrl, audience }: Auth0ClientConfig) {
    this.domain = domain;
    this.connectUrl = connectUrl;
    this.clientId = clientId;
    this.redirectUrl = redirectUrl;
    this.backendRedirectUrl = backendRedirectUrl;
    this.audience = audience;
  }

  loginWithRedirect = () => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: this.clientId,
      redirect_uri: `${this.connectUrl}/auth0/oauth`,
      scope: 'openid profile email offline_access',
      connection: 'google-oauth2',
      audience: this.audience,
      state: window.btoa(
        JSON.stringify({
          frontend_redirect: this.redirectUrl,
          backend_redirect_uri: this.backendRedirectUrl,
        }),
      ),
    });

    window.location.href = `${this.domain}/authorize?${params}`;
  };

  get user(): Auth0User {
    const user = window.localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }

  set user(user: Auth0User | null) {
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  getUserProfile = async () => {
    const profile = await api.connect.get<Auth0UserProfile>('/api/studio/user/profile/', {
      credentials: 'include',
    });
    this.user = { ...this.user, profile };
    return this.user;
  };

  getAccessTokenSilently = async () => {
    const result = await api.connect.post<Auth0SuccessResponse>('/api/auth0/refresh/', {
      credentials: 'include',
    });
    if (!result.success) {
      throw new Error('Auth token not refreshed.');
    }
  };

  get isAuthenticated() {
    return !!this.user;
  }

  logout = async () => {
    await api.connect.get(`/auth0/logout-redirect/?returnTo=${window.location.origin}`, {
      credentials: 'include',
    });
    this.user = null;
  };
}

export type Auth0ProviderProps = {
  client: Auth0Client;
  children?: React.ReactNode;
};

const Auth0Context = React.createContext<Auth0Client | undefined>(undefined);

export const Auth0Provider = ({ client, children }: Auth0ProviderProps): JSX.Element =>
  React.createElement(Auth0Context.Provider, { value: client }, children);

export const useAuth0 = () => {
  const client = useContext(Auth0Context);

  if (!client) {
    throw new Error('No Auth0Client set, use Auth0Provider to set one');
  }

  return client;
};
