import axios, { AxiosInstance } from 'axios';
import { spotterLogout } from './spotterAuthentication';

interface SpotterAxiosOptions {
  baseURL?: string;
  onRefreshTokenExpire?: () => void;
}

interface CircuitBreakerElement {
  url: string;
  createdAt: number;
}

/**
 * Class representing an Axios instance that is configured to interface with
 * Spotter based API endpoints
 */
export class SpotterAxios {
  public axiosInstance: AxiosInstance;

  private _onRefreshTokenExpire?: () => void;
  private circuitBreakerURLArray: CircuitBreakerElement[] = [];

  constructor(options: SpotterAxiosOptions) {
    this.axiosInstance = axios.create({
      baseURL: options.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    this._setAxiosRequestInterceptors();
    this._setAxiosResponseInterceptors();

    this._onRefreshTokenExpire = options.onRefreshTokenExpire;
    this._setupAuthCircuitBreaker();
  }

  _setupAuthCircuitBreaker = () => {
    // Will age off elements every 3 Seconds
    const MAX_RETRY_INTERVAL = 3000;
    setInterval(() => {
      this.circuitBreakerURLArray.forEach((item: CircuitBreakerElement) => {
        if (Date.now() - MAX_RETRY_INTERVAL > item.createdAt) {
          this.circuitBreakerURLArray.shift(); // remove first item
        }
      });
    }, 1000);
  };

  shouldCircuitBreak = (url: string) => {
    const MAX_CIRCUIT_BREAKER_ARRAY_SIZE = 5;
    this.circuitBreakerURLArray.push({ url: url, createdAt: Date.now() });
    if (this.circuitBreakerURLArray.length >= MAX_CIRCUIT_BREAKER_ARRAY_SIZE) {
      spotterLogout();
      return true;
    }
    return false;
  };

  /**
   * Retrieve the AxiosInstance of the SpotterAxios created
   * @returns the AxiosInstance that was created for this SpotterAxios instance
   */
  getInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  /**
   * Sets custom interceptors for the axios requests going out. By default
   * will attempt to add a new Authorization Bearer token based on the currently
   * logged in user.
   */
  private _setAxiosRequestInterceptors(): void {
    // Setup the request interceptor to add Authorization header for all outgoing requests
    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Sets custom interceptors for the axios responses coming back in. By default
   * will attempt to refresh the access token JWT set for the user within localStorage
   * for any responses coming back with a 401. If unable to refresh, will error out and call
   * the onTokenExpired callback if it was provided at creation of this instance.
   */
  private _setAxiosResponseInterceptors(): void {
    // Setup the response interceptor to check if a refresh token should be attempted to be set
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (axiosError: any) => {
        const originalConfig = axiosError.config;
        // If the response from the request is a 401, attempt to refresh the access JWT
        // Dont attempt to call refresh again if the refresh token is invalid, User needs to re-login in this scenario
        if (axiosError.response?.status === 401 && !['/auth0/refresh/', '/reset/'].includes(originalConfig.url)) {
          if (this.shouldCircuitBreak(originalConfig.url)) {
            return Promise.reject(axiosError);
          }

          try {
            await this.axiosInstance.post('/auth0/refresh/');

            return this.axiosInstance(originalConfig);
          } catch (error: any) {
            // If we are unauthorized for our request then call onTokenExpire callback

            if (axios.isAxiosError(error) && [500, 400, 401].includes(error.response?.status as number)) {
              this._onRefreshTokenExpire?.();
            }
            return Promise.reject(error);
          }
        }
        return Promise.reject(axiosError);
      },
    );
  }
}

export const spotterAxios = new SpotterAxios({
  baseURL: process.env.REACT_APP_BACKEND_BOWSER_API_CONTEXT_ROOT || '<UPDATE baseURL TO SPOTTER BACKEND HOST EXPECTED>',
  onRefreshTokenExpire: () => {
    window.location.assign('/login');
  },
});
