// material-ui
import SpotterLogo from '../../lib/core/SpotterLogo';
import { Grid, useMediaQuery, Theme, Typography } from '@mui/material';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const mediaLgMatch = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  return (
    <>
      {mediaLgMatch && <SpotterLogo fillColor="black" width="124px" />}
      {!mediaLgMatch && <SpotterLogo fillColor="black" width="100px" height="24px" />}
    </>
  );
};

export default Logo;
