import { createSlice, createAsyncThunk, combineReducers } from '@reduxjs/toolkit';

import { API } from '../../../../lib/core/api/API';
import { FetchAllState, PaginationPages } from '../../../../lib/core/api/types';
import { SpotterAxios, spotterAxios } from '../../../../lib/core/authentication/spotterAxios';

const name = 'tasks';

export const fetchTasks = createAsyncThunk(
  name,
  async (params: Record<string, any>, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const api = new API({
      endpoint: '/tasks/',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });

    return api.fetchAll(dispatch, rejectWithValue, params);
  },
);

export const tasks = createSlice({
  name: name,
  initialState: {
    results: [],
    rollup: {},
    pages: {
      totalPages: 0,
      pageSize: 0,
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      startingRecord: 0,
      endingRecord: 0,
      totalRecords: 0,
    } as PaginationPages,
    loading: false,
    errored: false,
    fulfilled: false,
    totalRecords: 0,
  } as FetchAllState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTasks.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(fetchTasks.rejected, (state, action) => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.pages = action.payload.pages;
      state.results = action.payload.results;
      state.rollup = action.payload.rollup;
      state.totalRecords = action.payload.totalRecords;
    });
  },
});

export default tasks.reducer;
