// External Dependencies
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Internal Imports
import { store } from './store';

// Styling Imports
import './style/App.scss';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';
import ThemeCustomization from './themes';
import { BannerMessageProvider, Position } from './components/BannerMessage';
import { AppProviders } from './providers';

const App: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <AppProviders>
        <BannerMessageProvider position={{ vertical: 'top', horizontal: 'right' } as Position}>
          <BrowserRouter>
            <ThemeCustomization>
              <ScrollTop>
                <Routes></Routes>
              </ScrollTop>
            </ThemeCustomization>
          </BrowserRouter>
        </BannerMessageProvider>
      </AppProviders>
    </Provider>
  );
};

export default App;
