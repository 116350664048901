import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../../lib/core/api/API';
import { spotterAxios } from '../../../../lib/core/authentication/spotterAxios';
import { FetchObjectState } from '../../../../lib/core/api/types';

const name = 'userProfile';

export const getProfile = createAsyncThunk(
  name,
  async (_: void, { dispatch, getState, rejectWithValue, fulfillWithValue }: any) => {
    const api = new API({
      endpoint: '/users/profile/',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });
    return api.fetch(dispatch, rejectWithValue);
  },
);

export const userProfile = createSlice({
  name: name,
  initialState: {
    data: {},
    loading: false,
    errored: false,
    fulfilled: false,
  } as FetchObjectState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProfile.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(getProfile.rejected, state => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.data = action.payload;
    });
  },
});

export default userProfile.reducer;
