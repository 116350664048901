import { Container, Grid, Typography, Button, TextField, Divider } from '@mui/material';
import { useContext, useState } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

// import Button from '../../themes/overrides/Button';
import MainCard from '../MainCard';
import { SearchDatePickerField, SearchDropdownField, SearchNumberField, SearchTextField } from './SearchFields';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

interface dropdownOption {
  key: string;
  value: string;
}
export interface SearchFormFields {
  type: string;
  value?: string;
  label: string;
  id: string;
  dropdownValues?: dropdownOption[];
}

interface DashboardSearchFormProps {
  loading: boolean;
  formValues: SearchFormFields[];
  onFormSubmit?: (values: SearchFormFields) => void;
  filterKey?: string;
  onlySearch?: boolean;
  noSearch?: boolean;
}

const DashboardSearchTool: React.FunctionComponent<DashboardSearchFormProps> = ({
  loading,
  formValues,
  onFormSubmit,
  filterKey,
  onlySearch,
  noSearch,
}: DashboardSearchFormProps) => {
  const onFormSubmitHandler = (values: any) => {
    if (!values?.search) {
      values.search = '';
    }
    onFormSubmit?.(values);
  };
  const [resetToggle, setResetToggle] = useState<boolean>(false);
  // const { filters, setFilters } = useContext(FilterContext);

  const renderFormFields = push => {
    const startDate = '';
    const endDate = '';

    const textFields = noSearch
      ? []
      : [
          <Field
            id={'search'}
            key={'search'}
            component={SearchTextField}
            // type="text"
            name="search"
            label="Search"
            resetToggle={resetToggle}
            defaultValue={''}
            style={{ paddingRight: '5px', paddingBottom: '5px', width: '100%' }}
          />,
        ];
    // const textFields = []; // Until search works again
    if (onlySearch) {
      return <>{textFields}</>;
    }
    const dropdownFields = [];
    let comparisonFields = null;
    const datePickerFields = [
      <Field
        id="start_date"
        key={'start_date'}
        component={SearchDatePickerField}
        // type="text"
        name="start_date"
        label="From"
        value={startDate}
        retainValue={startDate}
        resetToggle={resetToggle}
        style={{ paddingRight: '5px', paddingBottom: '5px', width: '50%' }}
      />,
      <Field
        id="end_date"
        key={'end_date'}
        component={SearchDatePickerField}
        // type="text"
        name="end_date"
        label="To"
        value={startDate}
        resetToggle={resetToggle}
        retainValue={endDate}
        style={{ paddingRight: '5px', paddingBottom: '5px', width: '50%' }}
      />,
    ];

    const dropdownCount = formValues ? formValues.filter(f => f.type === 'dropdown').length : 0;
    const dropdownWidth = dropdownCount > 0 ? `${100 / dropdownCount}%` : '100%';

    if (formValues) {
      formValues.forEach((f, i) => {
        const retainValue = f.label;
        switch (f.type) {
          case 'dropdown':
            dropdownFields.push(
              <Field
                id={f.id}
                key={f.id}
                component={SearchDropdownField}
                // type="text"
                name={f.id}
                label={f.label}
                retainValue={retainValue}
                value={retainValue}
                resetToggle={resetToggle}
                style={{ paddingRight: '5px', paddingBottom: '5px', width: dropdownWidth }}
                dropDownOptions={f.dropdownValues}
              />,
            );
            break;
          case 'text':
            textFields.push(
              <Field
                id={f.id}
                component={SearchTextField}
                // type="text"
                name={f.id}
                label={f.label}
                resetToggle={resetToggle}
                style={{ paddingRight: '5px', paddingBottom: '5px', width: '100%' }}
              />,
            );
            break;
          case 'comparison':
            comparisonFields = (
              <>
                <FieldArray name={f.id}>
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={name}>
                        <Field
                          style={{ width: '33.33%', paddingBottom: '5px' }}
                          component={SearchDropdownField}
                          name={`${name}.comparisonKey`}
                          label={f.label}
                          value={f.label}
                          resetToggle={resetToggle}
                          dropDownOptions={f.dropdownValues}
                        />
                        <Field
                          style={{ paddingLeft: '5px', width: '33.33%', paddingBottom: '5px' }}
                          component={SearchDropdownField}
                          name={`${name}.operator`}
                          label="Operator"
                          value={f.label}
                          resetToggle={resetToggle}
                          dropDownOptions={[
                            { key: 'gt', value: '>' },
                            { key: 'gte', value: '>=' },
                            { key: 'lt', value: '<' },
                            { key: 'lte', value: '<=' },
                            { key: 'eq', value: '=' },
                          ]}
                        />
                        <Field
                          id={`${index}_comparison_value`}
                          component={SearchNumberField}
                          // type="text"
                          name={`${name}.comparisonValue`}
                          label="Value"
                          resetToggle={resetToggle}
                          style={{ paddingLeft: '0.33%', width: '33%', paddingBottom: '5px' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CloseIcon
                                  sx={{ cursor: 'pointer', color: 'red' }}
                                  onClick={() => fields.remove(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
                <Button onClick={() => push(f.id, undefined)}>Add {f.label}</Button>
              </>
            );
            break;
        }
      });
    }

    return (
      <div>
        {textFields}
        <Divider light />
        {dropdownFields}
        <Divider light />
        {comparisonFields}
        <Divider light />
        {/* Add back when date range filters are available */}
        {/*{datePickerFields}*/}
      </div>
    );
  };

  const formValidation = (values: any) => {
    const errors: { key?: string; operator?: string; value?: string } = {};
    if (values.statistic) {
      values.statistic.forEach(value => {
        if (
          value &&
          [value.comparisonKey, value.operator, value.comparisonValue].some(element => element != undefined)
        ) {
          errors.key = !value.comparisonKey ? 'Required' : errors.key;
          errors.operator = !value.operator ? 'Required' : errors.operator;
          errors.value = !value.comparisonValue ? 'Required' : errors.value;
        }
      });
    }

    return errors;
  };

  const initialValues = {};
  return (
    <MainCard content={true}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} justifyContent="flex-start">
          <Typography variant="h4" align="left">
            {'Search'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} paddingTop="20px">
          <Form
            onSubmit={onFormSubmitHandler}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialValues}
            validate={formValidation}
            render={({
              form,
              form: {
                mutators: { push },
              },
              handleSubmit,
              invalid,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    {renderFormFields(push)}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} paddingTop="10px">
                    <Button type="submit" variant="outlined" color="primary" disabled={invalid || loading}>
                      <Typography>Submit</Typography>
                    </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        form.reset();
                        setResetToggle(prev => !prev);
                      }}
                      disabled={loading}
                    >
                      <Typography>Reset</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
// render(<DashboardSearchTool loading={false} />, document.getElementById('root'))
export default DashboardSearchTool;
