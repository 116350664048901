import { createSlice, createAsyncThunk, combineReducers } from '@reduxjs/toolkit';
import { API } from '../../../lib/core/api/API';
import { spotterAxios } from '../../../lib/core/authentication/spotterAxios';
import { FetchAllState, PaginationPages } from '../../../lib/core/api/types';

const name = 'userActivity';

export const fetchUserActivity = createAsyncThunk(
  name,
  async (params: Record<string, any>, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    params = { ...params, count: 90 };
    const api = new API({
      endpoint: '/metrics/daily-activity',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });
    return api.fetchAll(dispatch, rejectWithValue, params);
  },
);

export const userActivity = createSlice({
  name: name,
  initialState: {
    results: [],
    rollup: {},
    pages: {
      totalPages: 0,
      pageSize: 0,
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      startingRecord: 0,
      endingRecord: 0,
      totalRecords: 0,
    } as PaginationPages,
    loading: false,
    errored: false,
    fulfilled: false,
    totalRecords: 0,
  } as FetchAllState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserActivity.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(fetchUserActivity.rejected, (state, action) => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(fetchUserActivity.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.pages = action.payload.pages;
      state.results = action.payload.results;
      state.rollup = action.payload.rollup;
      state.totalRecords = action.payload.totalRecords;
    });
  },
});

export default userActivity.reducer;
