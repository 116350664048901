import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../../lib/core/api/API';
import { FetchObjectState } from '../../../../lib/core/api/types';
import { spotterAxios } from '../../../../lib/core/authentication/spotterAxios';

const name = 'task';

export const fetchTaskById = createAsyncThunk(
  name,
  async (
    { id, params }: { id: string; params: Record<string, any> },
    { dispatch, getState, rejectWithValue, fulfillWithValue },
  ) => {
    const api = new API({
      endpoint: '/tasks/',
      axiosInstance: spotterAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });
    return api.fetchById(dispatch, rejectWithValue, id, params);
  },
);

export const task = createSlice({
  name: name,
  initialState: {
    data: {},
    loading: false,
    errored: false,
    fulfilled: false,
  } as FetchObjectState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTaskById.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(fetchTaskById.rejected, state => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(fetchTaskById.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.data = action.payload;
    });
  },
});

export default task.reducer;
