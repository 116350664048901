import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

// project import
import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';
import Transitions from '../../../../../components/@extended/Transitions';

// assets
// import avatar1 from 'assets/images/users/avatar-1.png';

import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import MainCard from '../../../../../components/MainCard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../../../store/hooks';
import { spotterAxios } from '../../../../../lib/core/authentication/spotterAxios';
import { spotterLogout } from '../../../../../lib/core/authentication/spotterAuthentication';

interface TabPanel {
  children: any;
  value: any;
  index: number;
  dir: any;
}
// tab panel wrapper
function TabPanel({ children, value, index, dir, ...other }: TabPanel) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      dir={dir}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const [avatar, setAvatar] = useState('https://www.w3schools.com/howto/img_avatar.png');
  const userProfile = useSelector(state => state.userProfile);
  const [fullName, setFullName] = useState(null);
  const theme = useTheme<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile.data.profilePicture) {
      setAvatar(userProfile.data.profilePicture);
      setFullName(`${userProfile.data.firstName} ${userProfile.data.lastName}`);
    }
  }, [userProfile]);

  const handleLogout = () => {
    spotterLogout();
    navigate('/login');
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event_: any, newValue: any) => {
    setValue(newValue);
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar
            alt="profile user"
            src={avatar}
            sx={{ width: 32, height: 32 }}
            imgProps={{ referrerPolicy: 'no-referrer' }}
          />
          <Typography variant="subtitle1">{fullName ? fullName : userProfile.data.username}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar
                              alt="profile user"
                              src={avatar}
                              sx={{ width: 32, height: 32 }}
                              imgProps={{ referrerPolicy: 'no-referrer' }}
                            />
                            <Stack>
                              <Typography variant="h6">{userProfile.data.username}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {userProfile.data.permissions.map((role: string) => role.toUpperCase()).join(', ')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        {/* <Grid item>
                          <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton>
                        </Grid> */}
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
