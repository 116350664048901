import { createContext, forwardRef, ReactNode, useContext, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSelector } from '../store/hooks';

export interface State extends Position {
  open: boolean;
}

export interface Position {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right' | 'center';
}

export interface BannerMessage {
  id: string;
  message: string;
  status: 'error' | 'warning' | 'info' | 'success';
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BannerMessageProvider = ({ children, position }: { children: ReactNode; position: Position }) => {
  const [state, setState] = useState<State>({ ...position, open: false });
  const { vertical, horizontal, open } = state;
  const bannerMessage = useSelector(state => state.bannerMessage);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    if (bannerMessage.message.length) {
      setState(prev => ({ ...prev, open: true }));
    }
  }, [bannerMessage.id]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={bannerMessage.message}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={bannerMessage.status} sx={{ width: '100%' }}>
          {bannerMessage.message}
        </Alert>
      </Snackbar>
      {children}
    </>
  );
};
